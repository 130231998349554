import { get } from '../utils/request'
import { inject } from 'dependency-injection-es6'
import ChartOfAccountService from './ChartOfAccountService'
import apiRoot from '../_data/apiRoot'

import TransactionContactService from './TransactionContactService'

class TransactionExpense {
  @inject(ChartOfAccountService) chartOfAccount: ChartOfAccountService
  @inject(TransactionContactService) transContact: TransactionContactService
  expenseDataById = null

  async getExpenses() {
    const pageStart = 0
    const pageLength = 1000
    const response = await get(
      'expense',
      `expenses?page=${pageStart}&page_length=${pageLength}&transaction_type=expenses`,
      'GET',
    )
    return response[0].data
  }

  async getExpenseById(id) {
    const response = await get('expense', `expenses/${id}`, 'GET')
    this.expenseDataById = response.expenses[0]
    this.expenseDataById.expense_list = response.expense_list
    this.expenseDataById.expense_tag = response.expense_tag
    return this.expenseDataById
  }
  async calculateTotalAmount(list) {
    var totalAmount = 0
    // get total amount using input list items amount
    list.map((list) => {
      if (!list.amount) {
        list.amount = 0
      }
      totalAmount = Number(totalAmount) + Number(list.amount)
    })
    return totalAmount
  }

  async submitSelectedTags(selectedTags, selectedExpense_expense_tag) {
    if (selectedTags.length == 0 && selectedExpense_expense_tag) {
      var tagArray = []
      selectedExpense_expense_tag.map((tag) =>
        tagArray.push({ tag_id: tag.tag_id, transaction_type: 'expense' }),
      )
      return tagArray
    } else {
      return selectedTags
    }
  }
  async createLedgerTransaction(payment_date, payment_account, totalAmount, memo, inputList) {
    var ledgerTransaction = {
      chart_of_accounts: [],
      credits: [],
      debits: [],
      memos: [],
      transaction_date: payment_date,
    }
    function bindChartOfAccount(coa, debit, credit, memo) {
      ledgerTransaction.chart_of_accounts.push(coa)
      ledgerTransaction.debits.push(debit)
      ledgerTransaction.credits.push(credit)
      ledgerTransaction.memos.push(memo)
    }
    inputList.map((list) => {
      bindChartOfAccount(Number(payment_account), Number(0.0), Number(list.amount), memo)
      bindChartOfAccount(Number(list.category), Number(list.amount), Number(0.0), list.description)
    })
    return await this.chartOfAccount.storeLedgerTransaction(ledgerTransaction)
  }

  async updateLedgerTransaction(
    trans_id,
    payment_date,
    payment_account,
    totalAmount,
    memo,
    inputList,
  ) {
    var ledgerTransaction = {
      chart_of_accounts: [],
      credits: [],
      debits: [],
      memos: [],
      transaction_date: payment_date,
    }

    function bindChartOfAccount(coa, debit, credit, memo) {
      ledgerTransaction.chart_of_accounts.push(coa)
      ledgerTransaction.debits.push(debit)
      ledgerTransaction.credits.push(credit)
      ledgerTransaction.memos.push(memo)
    }

    inputList.map((list) => {
      bindChartOfAccount(Number(payment_account), Number(0.0), Number(list.amount), memo)
      bindChartOfAccount(Number(list.category), Number(list.amount), Number(0.0), list.description)
    })
    return await this.chartOfAccount.updateLedgerTransaction(ledgerTransaction, trans_id)
  }

  async deleteLedgerTransaction(ledger_transaction_id) {
    return await this.chartOfAccount.deleteLedgerTransaction(ledger_transaction_id)
  }
  async getExpensesBySupplierID(id, page = 1, sizePerPage = 10, filter) {
    const filterStr = JSON.stringify(filter)
    const response = await get(
      'expense',
      `transaction-list-by-supplier?page=${page}&page_length=${sizePerPage}&contactID=${id}&transaction_type=expenses&search=${filterStr}`,
      'GET',
    )
    return response
  }

  async getExpensePDFURLById(id) {
    const response = await get('expense', `expenses/${id}`, 'GET')
    if (response.expenses[0].pdf_url) {
      var apiRootValue = `${apiRoot['expense']}`
      var pdf_url = apiRootValue.split('api/')[0] + response.expenses[0].pdf_url
      return pdf_url
    } else {
      return 'empty'
    }
  }
}
export default TransactionExpense
