import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { download, get, post, printPdf } from '../../../utils/request'
import { selectAccountTransactions } from '../../selectors/banking/bankingAccount'

export const ADD_BANK_ACCOUNT = createTypes('ZOOM/BANKING/ADD_ACCOUNT')
export const GET_BANK_ACCOUNTS = createTypes('ZOOM/BANKING/GET_BANK_ACCOUNTS')
export const DELETE_BANK_ACCOUNT = createTypes('ZOOM/BANKING/DELETE_BANK_ACCOUNT')
export const GET_BANKING_PROVIDERS = createTypes('ZOOM/BANKING/GET_BANKING_PROVIDERS')
export const GET_FASTLINK_PARAMS = createTypes('ZOOM/BANKING/GET_FASTLINK_PARAMS')
export const GET_ACCOUNT_TRANSACTIONS = createTypes('ZOOM/BANKING/GET_ACCOUNT_TRANSACTIONS')
export const CREATE_ACCOUNT_TRANSACTION = createTypes('ZOOM/BANKING/CREATE_ACCOUNT_TRANSACTION')

const getBankingProvidersAction = {
  do: (params) => createAction(GET_BANKING_PROVIDERS.DO, params),
  success: (providers) => createAction(GET_BANKING_PROVIDERS.SUCCESS, { payload: providers }),
  failed: (errors) => createAction(GET_BANKING_PROVIDERS.FAILED, { errors }),
}
const getFastLinkParamsAction = {
  do: (params) => createAction(GET_FASTLINK_PARAMS.DO, params),
  success: (providers) => createAction(GET_FASTLINK_PARAMS.SUCCESS, { payload: providers }),
  failed: (errors) => createAction(GET_FASTLINK_PARAMS.FAILED, { errors }),
}
const getAccountTransactionsAction = {
  do: (params) => createAction(GET_ACCOUNT_TRANSACTIONS.DO, params),
  success: (accountTransactions) =>
    createAction(GET_ACCOUNT_TRANSACTIONS.SUCCESS, { payload: accountTransactions }),
  failed: (errors) => createAction(GET_ACCOUNT_TRANSACTIONS.FAILED, { errors }),
}
const createAccountTransactionAction = {
  do: (params) => createAction(CREATE_ACCOUNT_TRANSACTION.DO, params),
  success: () => createAction(CREATE_ACCOUNT_TRANSACTION.SUCCESS),
  failed: (errors) => createAction(CREATE_ACCOUNT_TRANSACTION.FAILED, { errors }),
}
const addBankAccountAction = {
  do: (params) => createAction(ADD_BANK_ACCOUNT.DO, params),
  success: () => createAction(ADD_BANK_ACCOUNT.SUCCESS),
  failed: (errors) => createAction(ADD_BANK_ACCOUNT.FAILED, { errors }),
}
const getBankAccountsAction = {
  do: (params) => createAction(GET_BANK_ACCOUNTS.DO, params),
  success: (accounts) => createAction(GET_BANK_ACCOUNTS.SUCCESS, { payload: accounts }),
  failed: (errors) => createAction(GET_BANK_ACCOUNTS.FAILED, { payload: errors }),
}
const deleteBankAccountAction = {
  do: () => createAction(DELETE_BANK_ACCOUNT.DO),
  success: () => createAction(DELETE_BANK_ACCOUNT.SUCCESS),
  failed: (errors) => createAction(DELETE_BANK_ACCOUNT.FAILED, { errors }),
}

const getBankingProviders =
  (keyword = '') =>
  async (dispatch) => {
    try {
      dispatch(getBankingProvidersAction.do())
      const resp = await get(`banking`, `providers?keyword=${keyword}`, 'GET')
      dispatch(getBankingProvidersAction.success(resp?.data?.provider))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading banks.'
      dispatch(getBankingProvidersAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getFastLinkParams = () => async (dispatch) => {
  try {
    dispatch(getFastLinkParamsAction.do())
    const resp = await get(`banking`, 'fast-link-token', 'GET')
    dispatch(getFastLinkParamsAction.success(resp?.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while loading fast link params.'
    dispatch(getFastLinkParamsAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}
const getBankAccounts =
  (pageStart = 0, pageLength = 100) =>
  async (dispatch) => {
    try {
      dispatch(getBankAccountsAction.do())
      const resp = await get(
        `banking`,
        `accounts?page=${pageStart}&page_length=${pageLength}`,
        'GET',
      )
      dispatch(getBankAccountsAction.success(resp.data))
      return resp
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading accounts.'
      dispatch(getBankAccountsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getAccountTransactions =
  (id, type = 'review') =>
  async (dispatch) => {
    try {
      dispatch(getAccountTransactionsAction.do())
      const resp = await get(
        `banking`,
        `transactions?account_id=${id}&type=${type}&page=1&page_length=1000`,
        'GET',
      )
      dispatch(getAccountTransactionsAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading transactions.'
      dispatch(getAccountTransactionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const createAccountTransaction = (params) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(createAccountTransactionAction.do())
      const resp = await post(`banking`, `transactions`, params)
      dispatch(createAccountTransactionAction.success(resp.data))
      resolve()
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while creating transaction.'
      dispatch(createAccountTransactionAction.failed(error))
      dispatch(showErrorToastAction(error))
      reject()
    }
  })
}
const confirmRuleForTransaction = (transactionId, ruleId) => async (dispatch, getState) => {
  try {
    dispatch(getAccountTransactionsAction.do())
    await post(`banking`, `transactions/${transactionId}/apply_rule`, {
      rule_id: ruleId,
    })
    const newTransactions = [...selectAccountTransactions(getState())]
    const index = newTransactions.findIndex((transaction) => transaction.id === transactionId)
    newTransactions.splice(index, 1)
    dispatch(getAccountTransactionsAction.success(newTransactions))
    return dispatch(showSuccessToastAction('Successfully applied'))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while apply rule.'
    dispatch(getAccountTransactionsAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const getAccountTransactionsReviewInPdf =
  (id, type = 'review') =>
  async (dispatch) => {
    try {
      await printPdf(
        `banking`,
        `transactions_reviews_in_pdf?account_id=${id}&type=${type}&page=1&page_length=1000`,
        'GET',
      )
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading transactions.'
      dispatch(getAccountTransactionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const getAccountTransactionsCategorizedInPdf =
  (id, type = 'categorized') =>
  async (dispatch) => {
    try {
      await download(
        `banking`,
        `transactions_categorized_in_pdf?account_id=${id}&type=${type}&page=1&page_length=1000`,
        'GET',
      )
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading transactions.'
      dispatch(getAccountTransactionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getAccountTransactionsExcludedInPdf =
  (id, type = 'categorized') =>
  async (dispatch) => {
    try {
      await download(
        `banking`,
        `transactions_excluded_in_pdf?account_id=${id}&type=${type}&page=1&page_length=1000`,
        'GET',
      )
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading transactions.'
      dispatch(getAccountTransactionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getAccountTransactionsSkipedInPdf =
  (id, type = 'categorized') =>
  async (dispatch) => {
    try {
      await download(
        `banking`,
        `transactions_skiped_in_pdf?account_id=${id}&type=${type}&page=1&page_length=1000`,
        'GET',
      )
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading transactions.'
      dispatch(getAccountTransactionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const getAccountTransactionsReviewInExcel =
  (id, type = 'review') =>
  async (dispatch) => {
    try {
      await download(
        `banking`,
        `transactions_reviews_in_excel?account_id=${id}&type=${type}&page=1&page_length=1000`,
        'GET',
      )
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading transactions.'
      dispatch(getAccountTransactionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const getAccountTransactionsCategorizedInExcel =
  (id, type = 'categorized') =>
  async (dispatch) => {
    try {
      await download(
        `banking`,
        `transactions_categorized_in_excel?account_id=${id}&type=${type}&page=1&page_length=1000`,
        'GET',
      )
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading transactions.'
      dispatch(getAccountTransactionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getAccountTransactionsExcludedInExcel =
  (id, type = 'categorized') =>
  async (dispatch) => {
    try {
      await download(
        `banking`,
        `transactions_excluded_in_excel?account_id=${id}&type=${type}&page=1&page_length=1000`,
        'GET',
      )
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading transactions.'
      dispatch(getAccountTransactionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getAccountTransactionsSkipedInExcel =
  (id, type = 'categorized') =>
  async (dispatch) => {
    try {
      await download(
        `banking`,
        `transactions_skiped_in_excel?account_id=${id}&type=${type}&page=1&page_length=1000`,
        'GET',
      )
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while loading transactions.'
      dispatch(getAccountTransactionsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
export {
  getBankingProvidersAction,
  addBankAccountAction,
  getBankAccountsAction,
  deleteBankAccountAction,
  getAccountTransactionsAction,
  getBankingProviders,
  getFastLinkParams,
  getBankAccounts,
  getAccountTransactions,
  confirmRuleForTransaction,
  createAccountTransaction,
  getAccountTransactionsReviewInPdf,
  getAccountTransactionsCategorizedInPdf,
  getAccountTransactionsExcludedInPdf,
  getAccountTransactionsSkipedInPdf,
  getAccountTransactionsReviewInExcel,
  getAccountTransactionsCategorizedInExcel,
  getAccountTransactionsExcludedInExcel,
  getAccountTransactionsSkipedInExcel,
}
