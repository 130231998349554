import { get, post } from '../utils/request'

class TaxRateService {
  taxRates = null

  async getTaxRates() {
    if (this.taxRates === null) {
      const response = await get('sales', 'tax-rates?page_length=100&page=1')
      this.taxRates = response.data
    }
    return this.taxRates
  }

  async calculateBulkTaxOnItems(transactionType, lines, contactId, addresses) {
    let items = lines?.map((item, index) => {
      return {
        index: index,
        is_taxable: item?.is_taxable,
        transaction_type: transactionType,
        customer_id: contactId,
        product_id: item.product_id,
        quantity: item.quantity ?? 0,
        rate: item.rate ?? 0,
        addresses: addresses,
      }
    })
    try {
      let response = await post('sales', 'bulk-tax-calculate', { items: items })
      if (response.success) {
        return lines?.map((item, index) => {
          let matchResponse = response.data.find((res) => res.index === index)
          return {
            ...item,
            is_taxable: matchResponse.is_taxable,
            taxa_on_product: matchResponse.taxa_on_product,
            tax_rates: matchResponse.tax_rates,
            tax_desc: matchResponse.desc,
            taxRates: matchResponse,
          }
        })
      }
      // eslint-disable-next-line no-unused-vars
    } catch (e) {
      console.error(e)
    }
  }
}
export default TaxRateService
