import { CButton } from '@coreui/react-pro'
import styled from 'styled-components'

const ZButton = styled(CButton)`
  border-radius: 0;
  // min-width: 114px;
  text-align: center;
  //padding-left: 20px;
  //padding-right: 20px;
`
export default ZButton
