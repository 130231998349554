import { createAction, createTypes } from '../../../utils/actions'
import { get, post, destroy } from '../../../utils/request'
import { showErrorToastAction, showSuccessToastAction } from '../global'

export const GET_RECONCILE_LIST = createTypes('ZOOM/BANKING/GET_RECONCILE_LIST')
export const GET_RECONCILE_INFO = createTypes('ZOOM/BANKING/GET_RECONCILE_INFO')
export const DO_RECONCILE = createTypes('ZOOM/BANKING/DO_RECONCILE')
export const DO_RECONCILE_LATER = createTypes('ZOOM/BANKING/DO_RECONCILE_LATER')
export const FETCH_RECONCILE_BY_COA_ID = createTypes('ZOOM/BANKING/FETCH_RECONCILE_BY_COA_ID')
export const DELETE_RECONCILE_BY_COA_ID = createTypes('ZOOM/BANKING/DELETE_RECONCILE_BY_COA_ID')
export const SHOW_RECONCILE = 'ZOOM/BANKING/SHOW_RECONCILE'
export const SET_RECONCILE_PARAMS = 'ZOOM/BANKING/SET_RECONCILE_PARAMS'
export const TOGGLE_CHECKED_ID = 'ZOOM/BANKING/TOGGLE_CHECKED_ID'
export const SET_CHECKED_IDS = 'ZOOM/BANKING/SET_CHECKED_IDS'

export const fetchReconcileByCoaIdAction = {
  do: (coaId) => createAction(FETCH_RECONCILE_BY_COA_ID.DO, { coaId }),
  success: (reconcileData) =>
    createAction(FETCH_RECONCILE_BY_COA_ID.SUCCESS, { payload: reconcileData }),
  failed: (errors) => createAction(FETCH_RECONCILE_BY_COA_ID.FAILED, { errors }),
}

export const getBankingReconcileListAction = {
  do: (params) => createAction(GET_RECONCILE_LIST.DO, params),
  success: (reconcileList) => createAction(GET_RECONCILE_LIST.SUCCESS, { payload: reconcileList }),
  failed: (errors) => createAction(GET_RECONCILE_LIST.FAILED, { errors }),
}

export const getBankingReconcileInfoAction = {
  do: (params) => createAction(GET_RECONCILE_INFO.DO, params),
  success: (reconcileInfo) => createAction(GET_RECONCILE_INFO.SUCCESS, { payload: reconcileInfo }),
  failed: (errors) => createAction(GET_RECONCILE_INFO.FAILED, { errors }),
}
const doReconcileAction = {
  do: (params) => createAction(DO_RECONCILE.DO, params),
  success: () => createAction(DO_RECONCILE.SUCCESS),
  failed: (errors) => createAction(DO_RECONCILE.FAILED, { errors }),
}
export const deleteReconcileByCoaIdAction = {
  do: (coaId) => createAction(DELETE_RECONCILE_BY_COA_ID.DO, { coaId }),
  success: () => createAction(DELETE_RECONCILE_BY_COA_ID.SUCCESS),
  failed: (errors) => createAction(DELETE_RECONCILE_BY_COA_ID.FAILED, { errors }),
}
const doReconcileLaterAction = {
  do: (params) => createAction(DO_RECONCILE_LATER.DO, params),
  success: () => createAction(DO_RECONCILE_LATER.SUCCESS),
  failed: (errors) => createAction(DO_RECONCILE_LATER.FAILED, { errors }),
}

const fetchReconcileDataByCoaId = (coaId) => async (dispatch) => {
  try {
    dispatch(fetchReconcileByCoaIdAction.do(coaId))
    const resp = await get('banking', `reconciles/get-data?coa_id=${coaId}`)
    dispatch(fetchReconcileByCoaIdAction.success(resp?.data))
    return resp
  } catch (e) {
    const error = e.body
    dispatch(fetchReconcileByCoaIdAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getBankingReconcileList = (params) => async (dispatch) => {
  try {
    const urlParams = new URLSearchParams()
    urlParams.append('page', 1)
    urlParams.append('page_length', 1000)
    if (params.coa_id?.value) params.coa_id = params.coa_id.value
    Object.keys(params).forEach((key) => {
      urlParams.append(key, params[key])
    })
    dispatch(getBankingReconcileListAction.do())
    const resp = await get('banking', `reconciles?${urlParams.toString()}`, 'GET')
    dispatch(getBankingReconcileListAction.success(resp?.data))
  } catch (e) {
    const error = e.body || 'An error occurred while loading bank reconciliation data.'
    dispatch(getBankingReconcileListAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const deleteReconcileByCoaId = (coaId) => async (dispatch) => {
  try {
    dispatch(deleteReconcileByCoaIdAction.do(coaId))
    await destroy('banking', `reconciles/delete?coa_id=${coaId}`)
    dispatch(showSuccessToastAction('Reconcile entry deleted successfully.'))
  } catch (e) {
    const error = e.body
    dispatch(deleteReconcileByCoaIdAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}
const setShowReconcile =
  (show = false) =>
  (dispatch) => {
    dispatch(createAction(SHOW_RECONCILE, { payload: show }))
  }

const setReconcileParams =
  (reconcileParams = {}) =>
  (dispatch) => {
    dispatch(createAction(SET_RECONCILE_PARAMS, { payload: reconcileParams }))
  }

const doReconcile = (params) => async (dispatch) => {
  try {
    dispatch(doReconcileAction.do())
    const resp = await post('banking', 'reconciles', params)
    dispatch(doReconcileAction.success())
    dispatch(showSuccessToastAction(resp.message))
  } catch (e) {
    const error = e.body || 'An error occurred during reconciliation.'
    dispatch(doReconcileAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const doReconcileLater = (params) => async (dispatch) => {
  try {
    dispatch(doReconcileLaterAction.do())
    const resp = await post('banking', 'reconciles/later', params)
    dispatch(doReconcileLaterAction.success())
    dispatch(showSuccessToastAction(resp.message))
  } catch (e) {
    const error = e.body || 'An error occurred during deferred reconciliation.'
    dispatch(doReconcileLaterAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const getBankingReconcileInfo = (id) => async (dispatch) => {
  try {
    dispatch(getBankingReconcileInfoAction.do())
    const resp = await get('banking', `reconciles/get_info?coa_id=${id}`)
    dispatch(getBankingReconcileInfoAction.success(resp))
    dispatch(showSuccessToastAction(resp.message))
  } catch (e) {
    const error = e.body || 'An error occurred while fetching reconciliation info.'
    dispatch(getBankingReconcileInfoAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

export {
  fetchReconcileDataByCoaId,
  getBankingReconcileList,
  setShowReconcile,
  setReconcileParams,
  doReconcile,
  doReconcileLater,
  getBankingReconcileInfo,
  deleteReconcileByCoaId,
}
