const apiRoot = {
  user: process.env.REACT_APP_USER_API_URL,
  sales: process.env.REACT_APP_SALES_API_URL,
  expense: process.env.REACT_APP_VENDOR_API_URL,
  report: process.env.REACT_APP_REPORT_API_URL,
  banking: process.env.REACT_APP_BANKING_API_URL,
  settings: process.env.REACT_APP_SETTINGS_API_URL,
  chartOfAccount: process.env.REACT_APP_CHART_OF_ACCOUNT_API_URL,
  subscription: process.env.REACT_APP_SUBSCRIPTION_API_URL,
  mainSite: process.env.REACT_APP_ACCOUNTING_MAIN_SITE,
  paymentAccount: 'Bank,Credit Card,Other Current Assets',
  CategoryPaymentAccount:
    'Expenses,Other Income,Other Current Liabilities,Accounts Receivable (A/R),Income,Accounts Payable (A/P),Bank',
}
export default apiRoot
