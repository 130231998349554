import React from 'react'
import styled from 'styled-components'
import { CAlert, CCloseButton } from '@coreui/react-pro'
import { useState } from 'react'

const ZAlert = ({ children, ...props }) => {
  const [visible, setVisible] = useState(true)
  return (
    <>
      {visible ? (
        <CAlert {...props} dismissible={false} className="d-flex align-items-center">
          <div className="flex-grow-1 d-flex">{children}</div>
          <CCloseButton
            onClick={(e) => {
              setVisible(false)
            }}
          />
        </CAlert>
      ) : (
        <></>
      )}
    </>
  )
}

export default styled(ZAlert)``
