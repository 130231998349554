import { createAction, createTypes } from '../../../utils/actions'
import { showErrorToastAction, showSuccessToastAction } from '../global'
import { destroy, download, get, post, printPdf, put } from '../../../utils/request'
import { transformForList as transformContact } from '../../../services/ContactService'

export const GET_SUPPLIERS = createTypes('ZOOM/EXPENSE/GET_SUPPLIERS')
export const CREATE_SUPPLIER = createTypes('ZOOM/EXPENSE/CREATE_SUPPLIER')
export const GET_SUPPLIER_BY_ID = createTypes('ZOOM/EXPENSE/GET_SUPPLIER_BY_ID')
export const DELETE_SUPPLIER = createTypes('ZOOM/EXPENSE/DELETE_SUPPLIER')
export const UPDATE_SUPPLIER = createTypes('ZOOM/EXPENSE/UPDATE_SUPPLIER')
export const INIT_SELECTED_SUPPLIER = 'ZOOM/EXPENSE/INIT_SELECTED_SUPPLIER'
export const GET_SUPPLIER_TERMS = createTypes('ZOOM/EXPENSE/GET_SUPPLIER_TERMS')
export const GET_TRANSACTION_COUNT = createTypes('ZOOM/SALES/GET_TRANSACTION_COUNT')
export const SET_PAGINATION_PARAMS = 'ZOOM/SALES/SET_PAGINATION_PARAMS'
export const setPaginationParams = (params) =>
  createAction(SET_PAGINATION_PARAMS, { payload: params })
export const GET_EXPENSE_CHART = createTypes('ZOOM/SALES/GET_EXPENSE_CHART')
export const GET_PROFITLOSS_CHART = createTypes('ZOOM/SALES/GET_PROFITLOSS_CHART')

const getSuppliersAction = {
  do: (params) => createAction(GET_SUPPLIERS.DO, params),
  success: (suppliers) => createAction(GET_SUPPLIERS.SUCCESS, { payload: suppliers }),
  failed: (errors) => createAction(GET_SUPPLIERS.FAILED, { payload: errors }),
}

const createSupplierAction = {
  do: (params) => createAction(CREATE_SUPPLIER.DO, params),
  success: () => createAction(CREATE_SUPPLIER.SUCCESS),
  failed: (errors) => createAction(CREATE_SUPPLIER.FAILED, { errors }),
}
const getTransactionCountAction = {
  do: () => createAction(GET_TRANSACTION_COUNT.DO),
  success: (count) => createAction(GET_TRANSACTION_COUNT.SUCCESS, { payload: count }),
  failed: (errors) => createAction(GET_TRANSACTION_COUNT.FAILED, { errors }),
}
const getExpenseChartDataAction = {
  do: (params) => createAction(GET_EXPENSE_CHART.DO, params),
  success: (count) => createAction(GET_EXPENSE_CHART.SUCCESS, { payload: count }),
  failed: (errors) => createAction(GET_EXPENSE_CHART.FAILED, { errors }),
}
const getProfitLossChartDataAction = {
  do: (params) => createAction(GET_PROFITLOSS_CHART.DO, params),
  success: (count) => createAction(GET_PROFITLOSS_CHART.SUCCESS, { payload: count }),
  failed: (errors) => createAction(GET_PROFITLOSS_CHART.FAILED, { errors }),
}
const getSupplierByIdAction = {
  do: (params) => createAction(GET_SUPPLIER_BY_ID.DO, params),
  success: (data) => createAction(GET_SUPPLIER_BY_ID.SUCCESS, { payload: data }),
  failed: (errors) => createAction(GET_SUPPLIER_BY_ID.FAILED, { errors }),
}

const deleteSupplierAction = {
  do: () => createAction(DELETE_SUPPLIER.DO),
  success: () => createAction(DELETE_SUPPLIER.SUCCESS),
  failed: (errors) => createAction(DELETE_SUPPLIER.FAILED, { errors }),
}

const updateSupplierAction = {
  do: (params) => createAction(UPDATE_SUPPLIER.DO, params),
  success: () => createAction(UPDATE_SUPPLIER.SUCCESS),
  failed: (errors) => createAction(UPDATE_SUPPLIER.FAILED, { errors }),
}

const getSupplierTermsAction = {
  do: () => createAction(GET_SUPPLIER_TERMS.DO),
  success: (terms) => createAction(GET_SUPPLIER_TERMS.SUCCESS, { payload: terms }),
  failed: (errors) => createAction(GET_SUPPLIER_TERMS.FAILED, { errors }),
}

const getSuppliers =
  (pageStart = 0, pageLength = 10, filter) =>
  async (dispatch) => {
    try {
      dispatch(getSuppliersAction.do())
      const filterStr = JSON.stringify(filter)
      const resp = await get(
        'sales',
        `contacts?page=${pageStart}&page_length=${pageLength}&type=vendor&search=${filterStr}`,
        'GET',
      )
      dispatch(
        setPaginationParams({
          page: resp.current_page,
          sizePerPage: parseInt(resp.per_page),
          totalSize: resp.total,
        }),
      )
      dispatch(getSuppliersAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting suppliers.'
      dispatch(getSuppliersAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }

const createSupplier = (params) => async (dispatch) => {
  try {
    dispatch(createSupplierAction.do())
    const resp = await post('sales', `contacts`, params)
    dispatch(createSupplierAction.success(resp))
    dispatch(showSuccessToastAction('Successfully created.'))
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while creating supplier data.'
    dispatch(createSupplierAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}

const initSelectedSupplier = () => (dispatch) => {
  dispatch(createAction(INIT_SELECTED_SUPPLIER))
}

const getSupplierById = (id) => async (dispatch) => {
  try {
    dispatch(getSupplierByIdAction.do())
    if (id) {
      const resp = await get('sales', `contacts/${id}`, 'GET')
      dispatch(getSupplierByIdAction.success(transformContact(resp)))
      return resp
      // if (Array.isArray(resp.add_infos) === true) {
      //   resp.add_infos = resp.add_infos[0]
      // }
      // if (Array.isArray(resp.notes) === true) {
      //   resp.notes = resp.notes[0].note
      // }
      // dispatch(getSupplierByIdAction.success(resp))
    } else {
      await dispatch(createAction(INIT_SELECTED_SUPPLIER))
    }
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting supplier data.'
    dispatch(getSupplierByIdAction.failed(error))
    return dispatch(showErrorToastAction(error))
  }
}

const deleteSupplier = (id) => async (dispatch) => {
  try {
    dispatch(deleteSupplierAction.do())
    const resp = await destroy('sales', `contacts/${id}`)
    dispatch(showSuccessToastAction('Successfully deleted.'))
    dispatch(deleteSupplierAction.success(resp))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating supplier data.'
    dispatch(deleteSupplierAction.failed(error))
    dispatch(showErrorToastAction(error))
  }
}

const updateSupplier = (id, params) => async (dispatch) => {
  try {
    dispatch(updateSupplierAction.do())
    const resp = await put('sales', `contacts/${id}`, params)
    dispatch(showSuccessToastAction('Successfully updated.'))
    dispatch(updateSupplierAction.success(resp))
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while updating supplier data.'
    dispatch(updateSupplierAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}

const getSupplierTerms =
  (pageStart = 0, pageLength = 1000) =>
  async (dispatch) => {
    try {
      dispatch(getSupplierTermsAction.do())
      const resp = await get('sales', `terms?page=${pageStart}&page_length=${pageLength}`, 'GET')
      return dispatch(getSupplierTermsAction.success(resp.data))
    } catch (e) {
      const error = e.body ? e.body : 'An error occurred while getting terms.'
      dispatch(getSupplierTermsAction.failed(error))
      return dispatch(showErrorToastAction(error))
    }
  }
const getTransactionCount = () => async (dispatch) => {
  try {
    dispatch(getTransactionCountAction.do())
    const resp = await get('expense', 'expenses/suppliers/get-transaction-count', 'GET')
    dispatch(getTransactionCountAction.success(resp.data))
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting Count.'
    return dispatch(showErrorToastAction(error))
  }
}

const getExpenseChartData = (params) => async (dispatch) => {
  try {
    dispatch(getExpenseChartDataAction.do())
    const resp = await get(
      'expense',
      `get-dashboard-details?start_date=${params.start_date}&end_date=${params.end_date}`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting Transection Chart Data.'
    dispatch(getExpenseChartDataAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}

const getProfitLossChartData = (params) => async (dispatch) => {
  try {
    dispatch(getProfitLossChartDataAction.do())
    const resp = await get('expense', `profit-loss-chart?range=${params}`, `GET`)
    dispatch(getProfitLossChartDataAction.success(resp))
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting Profit loss Charts.'
    dispatch(getProfitLossChartDataAction.failed(error))
    dispatch(showErrorToastAction(error))
    return false
  }
}

const downloadPdfReport = (filter) => async (dispatch) => {
  try {
    const filterStr = JSON.stringify(filter)
    const resp = await printPdf(
      'sales',
      `contacts-vendor-pdf?page=${1}&page_length=${1000}&type=vendor&search=${filterStr}`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting sales.'
    return dispatch(showErrorToastAction(error))
  }
}

const downloadExcelReport = (filter) => async (dispatch) => {
  try {
    const filterStr = JSON.stringify(filter)
    const resp = await download(
      'sales',
      `contacts-vendor-excel?page=${1}&page_length=${1000}&type=vendor&search=${filterStr}`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting products.'
    return dispatch(showErrorToastAction(error))
  }
}

const downloadVendorTransactionPdfReport = (id) => async (dispatch) => {
  try {
    const resp = await download(
      'expense',
      `transaction-list-by-supplier-pdf?page=${1}&page_length=${1000}&contactID=${id}&transaction_type=expenses`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting sales.'
    return dispatch(showErrorToastAction(error))
  }
}

const downloadVendorTransactionExcelReport = (id) => async (dispatch) => {
  try {
    const resp = await download(
      'expense',
      `transaction-list-by-supplier-excel?page=${1}&page_length=${1000}&contactID=${id}&transaction_type=expenses`,
      'GET',
    )
    return resp
  } catch (e) {
    const error = e.body ? e.body : 'An error occurred while getting products.'
    return dispatch(showErrorToastAction(error))
  }
}

const handleImportRequest = (data) => async (dispatch) => {
  try {
    const resp = await post('sales', `contacts/bulk-upload-vendors`, data)
    const { message, data: importData } = resp
    dispatch(showSuccessToastAction(message))
    return { message, data: importData }
  } catch (error) {
    const errorMessage = error.response?.data?.errors?.join(', ') || 'An unexpected error occurred.'
    dispatch(showErrorToastAction(errorMessage))
    return { errors: [errorMessage] }
  }
}

export {
  getSuppliersAction,
  createSupplierAction,
  getSupplierByIdAction,
  deleteSupplierAction,
  updateSupplierAction,
  getExpenseChartDataAction,
  getSuppliers,
  createSupplier,
  getSupplierById,
  deleteSupplier,
  updateSupplier,
  getSupplierTerms,
  getTransactionCount,
  getExpenseChartData,
  downloadPdfReport,
  downloadExcelReport,
  getProfitLossChartData,
  handleImportRequest,
  downloadVendorTransactionPdfReport,
  downloadVendorTransactionExcelReport,
  initSelectedSupplier,
}
